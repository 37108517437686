// import { dataAccounting } from "../../../components/selectAccountingPlan";
import {
    DecimalLength,
    b64Uri,
    cleanFloat,
    formatCurrency,
    getData,
    getElement,
    getJson,
    setSelect2Ajax,
} from "../../../../helpers";

class FormPayrollModule extends HTMLElement {
    connectedCallback() {
        this.addEvents();
        this.filterAccount();
        this.total();
    }

    addEvents() {
        const Tbody = getElement("[name=tbodyentry]", this);
        let row = Tbody.lastElementChild.cloneNode(true);
        //Acciones
        const Form = getElement("[data-form=form]", this);

        Form.elements.save?.addEventListener("click", (e) => {
            if (this.validate() & Form.reportValidity()) {
                // Preparar líneas
                this.prepareLines();
                Form.submit();
            }
        });

        Form.elements.new_line?.addEventListener("click", () => {
            let row2 = row.cloneNode(true);

            getElement("[name=debit]", row2).value = "";
            getElement("[name=credit]", row2).value = "";
            getElement("[name=description]", row2).value = "";
            getElement("[name=accountingplan_id]", row2).removeAttribute(
                "data-selected"
            );

            Tbody.appendChild(row2);
            let project = getJson(
                b64Uri(
                    getData(
                        getElement("[name=genproject]", this),
                        "data-data-selected"
                    ),
                    "decode"
                )
            );

            this.fillLines(project);
        });

        Form.elements.genproject.addEventListener("aj:select2", (e) => {
            this.fillLines(e.detail.data);
        });

        Form.elements.employee_people_id?.addEventListener(
            "aj:select2",
            (e) => {
                const Data = e.detail.data;
                Form.elements.description[0].value = `${getData(
                    e.target,
                    "data-payroll"
                )} ${Data.text}`;

                this.fillLines(null, true);
            }
        );

        Form.elements.description[0].addEventListener("change", (e) => {
            this.fillLines();
        });

        this.addEventListener("change", (e) => {
            const Target = e.target;

            if (Target.classList.contains("calcTotal")) {
                this.total();
            }

            if (Target.name == "debit" || Target.name == "credit") {
                this.getSibling(Target);
            }
        });

        Tbody.addEventListener("aj:select2", (e) => {
            const Target = e.target;
            const Data = e.detail.data;
            if (Target.name == "accountingplan_id") {
                // Identificar cada cuenta de fila para concatenar
                // dataAccounting.concat[getData(Target, "data-select2-id")] =
                //     Data.id;
                // dataAccounting.accountingplans = Object.values(
                //     dataAccounting.concat
                // );

                // Setear debe o haber
                const Column =
                    Target.parentElement.parentElement.parentElement
                        .nextElementSibling.firstElementChild;

                const Group = Data.accountinggroup;

                // Pendiente revisar esto solo se toma en cuento el grupo
                if (Group) {
                    switch (Data.rule) {
                        case 1:
                            if (Group.increment == "debit") {
                                Column.value = "credit";
                            } else {
                                Column.value = "debit";
                            }
                            break;

                        default:
                            Column.value = Group.increment;
                            break;
                    }
                }
            }
        });

        Tbody.addEventListener("cl:deleteRow", (e) => {
            this.filterAccount();
            this.total();
        });

        Tbody.addEventListener("click", (e) => {
            const Target = e.target;
            const BtnDiff = Target.closest("[name=btnDiff]");

            if (BtnDiff?.name == "btnDiff") {
                let total = this.total();
                const IptAmmout = BtnDiff.nextElementSibling;

                if (!IptAmmout.value.length) {
                    let t = Math.abs(total.total);

                    if (t > 0) {
                        IptAmmout.value = formatCurrency(t, "decimal");
                    }

                    this.total();
                }

                this.getSibling(IptAmmout);
            }
        });

        this.addEventListener("click", (e) => {
            const Target = e.target;
            const BtnClear = Target.closest("[name=clear]");
            if (BtnClear) {
                window.location.reload();
            }
        });
    }

    getSibling(Target) {
        const Parent = Target.parentElement.parentElement;
        let sibling = null;

        if (Target.name == "debit") {
            sibling = Parent.nextElementSibling;
        } else {
            sibling = Parent.previousElementSibling;
        }

        const Ipt = sibling.firstElementChild.querySelector("input");

        if (cleanFloat(Target.value) <= 0) {
            Target.value = "";
            Target.setAttribute("required", "");
        } else {
            Ipt.value = "";
            Ipt.removeAttribute("required");
        }
    }

    filterAccount() {
        const Tbody = getElement("[name=tbodyentry]", this);
        // Eliminar filtro de la cuenta eliminada
        // dataAccounting.concat = [];

        Array.from(Tbody.children).forEach((row, key) => {
            const SelectAccount = getElement("[name=accountingplan_id]", row);
            const Data = getJson(
                b64Uri(
                    getData(SelectAccount, "data-data-selected") ??
                        getData(SelectAccount, "data-default"),
                    "decode"
                )
            );

            // if (!Data?.error) {
            //     dataAccounting.concat["accountingplan_" + key] = Data.id;
            // }
        });

        // dataAccounting.accountingplans = Object.values(dataAccounting.concat);
    }

    total() {
        const Tbody = getElement("[name=tbodyentry]", this);
        const IptTotal =
            Tbody.nextElementSibling.firstElementChild.querySelector(
                "[name=total]"
            );
        const Label =
            Tbody.nextElementSibling.firstElementChild.querySelector("span");

        let total = { debit: 0, credit: 0, total: 0 };

        Array.from(Tbody.children).forEach((row) => {
            const IptDebit = getElement("[name=debit]", row);
            const IptCredit = getElement("[name=credit]", row);

            total.debit += cleanFloat(IptDebit.value);
            total.credit += cleanFloat(IptCredit.value);
        });

        total.total = total.debit - total.credit;
        total.total = parseFloat(total.total.toFixed(DecimalLength));
        IptTotal.value = formatCurrency(Math.abs(total.total), "decimal");

        if (total.total > 0) {
            Label.textContent = getData(Label, "data-sd");
        } else if (total.total < 0) {
            Label.textContent = getData(Label, "data-sc");
        } else {
            Label.textContent = getData(Label, "data-s0");
        }

        return total;
    }

    validate() {
        const Form = getElement("[data-form=form]", this);
        let total = this.total();
        let flag = true;

        Form.elements.total.setCustomValidity("");

        if (total.total != 0) {
            flag = false;
            Form.elements.total.setCustomValidity(
                getData(Form.elements.total, "data-custom-validity")
            );
        }

        return flag;
    }

    fillLines(data = null, replace = false) {
        const Tbody = getElement("[name=tbodyentry]", this);
        const Form = getElement("[data-form=form]", this);

        if (data?.id) {
            const IptProjects = Tbody.querySelectorAll("[name=project]");

            IptProjects.forEach((element) => {
                // if (!element.value) {
                setSelect2Ajax(element, data.id, data.name ?? data.text);
                // }
            });
        }

        const IptDescriptions = Tbody.querySelectorAll("[name=description]");

        IptDescriptions.forEach((element) => {
            if (!element.value || replace) {
                element.value = Form.elements.description[0].value;
            }
        });
    }

    prepareLines() {
        const Tbody = getElement("[name=tbodyentry]", this);
        const Form = getElement("[data-form=form]", this);
        let lines = [];

        Array.from(Tbody.children).forEach((row) => {
            const Project = getElement("[name=project]", row);
            const Description = getElement("[name=description]", row);
            const Account = getElement("[name=accountingplan_id]", row);
            const Debit = getElement("[name=debit]", row);
            const Credit = getElement("[name=credit]", row);

            // Validación extra
            if (
                Project.value &&
                Account.value &&
                (Debit.value || Credit.value)
            ) {
                let line = {
                    accountingplan_id: Account.value,
                    accountingplan_data: b64Uri(
                        getData(Account, "data-data-selected"),
                        "decode"
                    ),
                    accounting_column: Debit.value ? "debit" : "credit",
                    amount: cleanFloat(
                        Debit.value ? Debit.value : Credit.value
                    ),
                    description: Description.value,
                    project_id: Project.value,
                };

                lines.push(line);
            }
        });

        Form.elements.lines.value = b64Uri(lines);
    }

    // loadTemplate(data) {
    //     const Tbody = getElement("[name=tbodyentry]", this);
    //     const Form = getElement("[data-form=form]", this);

    //     Form.elements.description[0].value = data.description;
    //     setSelect2Ajax(
    //         Form.elements.genproject,
    //         data.lines[0].project.id,
    //         data.lines[0].project.name
    //     );

    //     Tbody.innerHTML = "";

    //     data.lines.forEach((line, key) => {
    //         Form.elements.new_line.click();
    //         // Esta línea ya no es útil porque se ha cambiado la estética del asiento
    //         // Tbody.children[key].querySelector("[name=column]").value =
    //         //     line.accounting_column;
    //         const Account = Tbody.children[key].querySelector(
    //             "[name=accountingplan_id]"
    //         );

    //         setSelect2Ajax(
    //             Account,
    //             line.accountingplan.id,
    //             `${line.accountingplan.number} - ${line.accountingplan.name}`,
    //             line.accountingplan
    //         );
    //     });
    // }
}

window.customElements.define("formpayroll-module", FormPayrollModule, {
    extends: "section",
});
