import { b64Uri, getElement, getJson, resetForm, setSelect2Ajax } from "../../../../helpers";

export class EmployeeModal extends HTMLDivElement {
    connectedCallback() {
        this.addEvents();
    }

    addEvents() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));
        console.log(data);

        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        Form.elements.dni.value = data.dni;
        Form.elements.name1.value = data.name1;
        Form.elements.name2.value = data.name2;
        Form.elements.surname1.value = data.surname1;
        Form.elements.surname2.value = data.surname2;

        if (data.accountingplan) {
            setSelect2Ajax(
                Form.elements.accountingplan_id,
                data.accountingplan.id,
                `${data.accountingplan.number} - ${data.accountingplan.name}`
            );
        }
    }
}

window.customElements.define("employee-modal", EmployeeModal, {
    extends: "div",
});
